.signin-page {
    display: flex;
    justify-content: center;
    /* Centers the card horizontally */
    align-items: center;
    /* Centers the card vertically */
    min-height: 80vh;
    /* Ensures the page takes the full height of the viewport */

}

.signin-card {
    max-width: 600px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    z-index: 2;
    background-color: #fff;
    border-radius: 16px;
    padding: 30px;
    margin-top: 10px;
}

.siginin-bg {
    background: url('../assets/images/logo600px.png') no-repeat center top;
    background-size: 90%;
    height: 60vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
}

.siginin-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(5, 77, 140, 0.65);
    z-index: 1;
}

.back-arrow {
    width: 40px;
    height: auto;
}

.signin-logo{
    width: 130px;
    height: auto;
}

.signin-content > .media a{
    color: var(--dark-blue-color) !important;
}

.signin-content > .media{
    border-bottom: 1px solid var(--dark-gray-color);
}