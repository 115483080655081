:root {
  --primary-color: #336799;
  --light-gray-color: #F6F6F8;
  --medium-gray-color: #d1cfcf;
  --dark-gray-color: #989898;
  --dark-blue-color: #162D44;
  --faded-blue-color: #d8dfe6;
  --mpesa-green-color: #43b02a;
}

button,
.button {
  box-shadow: none;
  /* Remove shadows from buttons */
}

.primary-outline-button {
  border: var(--primary-color) 2px solid !important;
  color: var(--primary-color) !important;
  background-color: transparent !important;
  border-radius: 5px;
}

.danger-outline-button {
  border: red 2px solid !important;
  color: red !important;
  background-color: transparent !important;
  border-radius: 5px;
}

.primary-bg {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.light-gray-bg {
  background-color: var(--light-gray-color) !important;
}

.dark-blue-bg {
  background-color: var(--dark-blue-color);
  color: white;
}

.main-footer {
  min-height: 70px;
}

#mainLogo {
  width: 120px;
  height: auto;
}

.primary-text-color {
  color: var(--dark-blue-color);
}

.light-primary-text-color {
  color: var(--primary-color);
}

.normal-text {
  color: var(--dark-blue-color);
  font-size: 24px;
  font-family: "Sarabun", sans-serif;
}

#logoLink {
  background-color: transparent !important;
}

button,
.button {
  border-radius: 8px !important;
}

.is-outline {
  border: var(--primary-color) solid 2px !important;
  color: var(--primary-color) !important;
  font-weight: bold;
}

.is-outline a,
.is-outline a:hover {
  color: var(--primary-color);
  text-decoration: none;
  background-color: transparent;
  font-weight: bold;
}

a.dropdown-item,
button.dropdown-item {
  padding-inline-end: 1rem !important;
}

.banner-buttons {
  display: flex;
  gap: 5px;
}

.banner-buttons>.button {
  /* Makes each button take up equal width */
  flex: 1;
  text-align: center;
  padding: 10px;
}

.big-margin {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.small-margin {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.story-section {
  margin-bottom: 20px;
}

.story-section h1 {
  font-size: 24px;
  font-weight: 800;
  text-transform: capitalize;
}

.story-section p {
  font-size: 16px;
}

.sidebar {
  border-right: var(--light-gray-color) 1px solid;
}

.sidebar-logo {
  width: 150px;
  height: auto;
}

.dash-section {
  background-color: #FFFFFF;
  border-radius: 16px;
  border: 1px solid var(--medium-gray-color);
  padding: 30px !important;
}

.section-title {
  font-size: 18px;
  padding-bottom: 20px;
}

.field .box {
  border: 1px solid #eaeaea;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: none;
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.2) !important;
  /* Adjust opacity to your liking */
}

/* Custom CSS for notification opacity */
.notification.is-danger {
  background-color: rgba(255, 0, 0, 0.2) !important;
  /* Light red with reduced opacity */
  border: 1px solid rgba(255, 0, 0, 0.4) !important;
  /* Light red border with reduced opacity */
}

.notification.is-success {
  background-color: rgba(0, 255, 0, 0.2) !important;
  /* Light green with reduced opacity */
  border: 1px solid rgba(0, 255, 0, 0.4) !important;
  /* Light green border with reduced opacity */
}

.notification.is-warning {
  background-color: rgba(255, 255, 0, 0.2) !important;
  /* Light yellow with reduced opacity */
  border: 1px solid rgba(255, 255, 0, 0.9) !important;
  /* Light yellow border with reduced opacity */
}

.notification.is-info {
  background-color: rgba(0, 255, 255, 0.2) !important;
  /* Light cyan with reduced opacity */
  border: 1px solid rgba(0, 255, 255, 0.9) !important;
  /* Light cyan border with reduced opacity */
}

.notification.is-primary {
  background-color: rgba(0, 0, 255, 0.2) !important;
  /* Light blue with reduced opacity */
  border: 1px solid rgba(0, 0, 255, 0.9) !important;
  /* Light blue border with reduced opacity */
}

a.default-color {
  color: inherit;
}

.is-white-text {
  color: #FFFFFF !important;
}

.contain-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Scale the image to fit inside the container */
  object-position: center;
  /* Center the image within the container */
}

/* Make the image cover the entire container, filling it completely */
.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Fill the container, maintaining the aspect ratio */
  object-position: center;
  /* Center the image */
}

.fit-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Ensures the whole image is visible without distortion */
}

.social-icons {
  display: flex;
  gap: 20px;
}

.icon-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  color: var(--dark-blue-color);
  font-size: 30px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.icon-circle:hover {
  background-color: var(--primary-color);
  /* Bulma primary color */
  color: white;
  border: 1px solid white;
}

.main-footer {
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: bold;
}

.main-navbar .navbar-item {
  padding: 10px 15px;
  transition: background-color 0.3s ease;
  color: var(--dark-blue-color);
}

.main-navbar .navbar-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: var(--primary-color);
}

.main-navbar {
  font-weight: bold;
  font-size: 17px;
  border-bottom: 1px solid var(--faded-blue-color);
}

.dash-card-title {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.with-min-height {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}

.mpesa-bg {
  background-color: var(--mpesa-green-color) !important;
  color: white !important;
}

.mpesa-bg:hover {
  background-color: var(--primary-color) !important;
}

.sidebar {
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  background-color: var(--light-gray-color);
}

.sidebar.is-active {
  transform: translateX(0);
}

.toggle-sidebar-btn {
  display: none !important;
}

.main {
  padding: 0px;
}

.sidebar .menu-list .menu-item,
.sidebar .menu-list a,
.sidebar .menu-list button {
  background-color: var(--light-gray-color);
  border-bottom: var(--medium-gray-color) solid 1px;
  border-radius: 0px;
}

.sidebar .menu-list a:hover {
  background-color: var(--primary-color);
  color: #FFFFFF;
}

.sidebar-logo-section {
  background-color: white;
  width: 100%;
  text-align: center;
}

/* General Navbar Styling */
.main-navbar .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #f8f9fa;
  height: 70px;
}

/* Logo */
.logo-link {
  display: flex;
  align-items: center;
}

.main-navbar .logo-link {
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.main-logo {
  height: 50px;
  width: auto;
}

/* Hamburger Menu Styling */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger-line {
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
  border-radius: 5px;
}

.main-navbar .navbar-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.main-navbar .navbar-center {
  display: flex;
  justify-content: center;
  flex: 1;
}

.main-navbar .navbar-end {
  display: flex;
  justify-content: flex-end;
}

.main-navbar .navbar-item {
  text-decoration: none;
  color: #333;
  margin: 0 15px;
}

/* Login button or user dropdown */
.login-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Dropdown */
.dropdown {
  position: relative;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 1000;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  display: block;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.dash-navbar {
  border-bottom: var(--faded-blue-color) 1px solid;
}

.dash-main {
  padding-top: 15px;
  padding-right: 20px;
}

.dash-logo {
  display: none;
}

.no_deco_link{
  text-decoration: none;
  color: var(--dark-blue-color);
}

@media (max-width: 1024px) {
  .main-navbar .hamburger {
    display: flex;
    position: fixed;
    right: 20px;
    top: 20px;
  }

  .main-navbar .navbar-menu {
    display: none;
    flex-direction: column;
    background-color: #f8f9fa;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    z-index: 1000;
    padding: 20px 0;
    align-items: flex-start;
    /* Align items to the left */
  }

  .main-navbar .navbar-menu.is-active {
    display: flex;
    border-top: 1px solid var(--medium-gray-color);
  }

  .main-navbar .navbar-center,
  .main-navbar .navbar-end {
    flex-direction: column;
    align-items: flex-start;
    /* Align menu items to the left */
    justify-content: flex-start;
    width: 100%;
    padding-left: 20px;
    /* Add some left padding for spacing */
  }

  .main-navbar .navbar-item {
    width: 100%;
    border-bottom: 1px solid var(--medium-gray-color);
  }

  .dash-profile-image {
    display: none !important;
  }

  .dash-navbar .navbar-end {
    position: relative;
    top: -40px;
    width: 30%;
  }

  .navbar-item.has-dropdown .navbar-dropdown {
    display: none;
  }

  .navbar-item.has-dropdown.is-active .navbar-dropdown {
    display: block;
  }

  #dashlogo {
    width: 100px;
    height: auto;
  }

  main {
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .title {
    font-size: 20px !important;
  }

  .social-icons {
    justify-content: center;
  }

  .main-footer .has-text-right {
    text-align: center !important;
  }
}

/* Default: Inline layout */
.phone-number-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* Space between elements */
}

.phone-number-container .country-code-dropdown {
  flex: 0 0 auto;
  /* Fixed size for the dropdown */
}

.phone-number-container .phone-number-input {
  flex: 1;
  /* Takes the remaining space */
}

@media (max-width: 768px) {
  .dash-logo {
    display: block;
  }

  .sidebar {
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background-color: #fff;
    z-index: 99;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
  }

  .sidebar.is-active {
    transform: translateX(0);
  }

  .toggle-sidebar-btn {
    display: block !important;
    position: fixed !important;
    top: 10px;
    right: 20px;
    background: transparent;
    z-index: 900;
  }

  .main-navbar .dropdown.is-right .dropdown-menu {
    left: 10px !important;
  }

  .big-margin {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .small-margin {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .dash-navbar .navbar-end {
    left: 100px;
    top: -81px;
    width: 200px;
  }

  .dash-navbar .navbar-end a {
    background-color: transparent;
  }

  .dash-navbar .navbar-end a {
    font-weight: bold;
    color: var(--primary-color);
  }

  .dash-navbar .logo-link {
    position: relative;
    top: -23px;
    left: 13px;
  }

  .dash-navbar {
    height: 68px;
  }

  .dash-navbar .navbar-link {
    text-align: center;
  }

  .dash-navbar .hamburger {
    display: flex;
    position: fixed;
    right: 20px;
    top: 20px;
  }

  .phone-number-container {
    flex-direction: column;
    align-items: stretch;
    /* Full-width elements */
  }

  .phone-number-container .country-code-dropdown,
  .phone-number-container .phone-number-input {
    width: 100%;
  }
}