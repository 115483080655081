.banner {
    position: relative;
    height: 100vh;
    display: flex;
    padding: 20px;
    background-image: url('../assets/images/church.jpg');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    overflow: hidden;
}

.banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 1;
}

.banner>* {
    position: relative;
    z-index: 2;
}

.banner h1 {
    text-align: center;
    margin: 0;
}

.overwrapping-divs {
    position: relative;
    height: 600px;
    overflow: hidden;
}

.overwrapping-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: transform 0.3s ease;
    border-radius: 26px;
    overflow: hidden;
    border: #fff solid 4px;
}

.overwrapping-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.overwrapping-item:nth-child(1) {
    z-index: 4;
    transform: translate(0, 210px);
    height: 200px;
    width: 200px;
}

.overwrapping-item:nth-child(2) {
    z-index: 3;
    transform: translate(350px, 210px);
    height: 200px;
    width: 200px;
}

.overwrapping-item:nth-child(3) {
    z-index: 2;
    transform: translate(160px, 60px);
    height: 250px;
    width: 250px;
}

.overwrapping-item:nth-child(4) {
    z-index: 1;
    transform: translate(90px, 140px);
    height: 400px;
    width: 400px;
}

.big-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 120%;
    font-family: "Sarabun", sans-serif;
}

@media (min-width: 769px) {
    .banner {
        justify-content: flex-start;
        padding-left: 20px;
    }

    .banner h1 {
        width: 50%;
    }
}

@media (max-width: 768px) {

    .banner {
        justify-content: center;
        height: auto;
    }

    .banner h1 {
        width: 100%;
    }
    .big-title{
        font-size: 30px;
    }

}

