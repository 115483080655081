body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;
}

body,
button,
input,
optgroup,
select,
textarea {
  font-family: "Sarabun", sans-serif !important;
}